import messages from '@utils/messages';
export var getListName = function getListName(formatMessage) {
  return function (list) {
    return list.name === 'SYSTEM_CREATED_LIST' ? formatMessage(messages.LIST_NUM_ONE) : list.name;
  };
};
export var validateNotAMember = function validateNotAMember(value, list, ccEmailArray, formatMessage) {
  var emails = ccEmailArray(value);
  var memberEmail = emails.find(function (email) {
    var _list$membersDetail;

    return list.ownerDetail.email === email || ((_list$membersDetail = list.membersDetail) === null || _list$membersDetail === void 0 ? void 0 : _list$membersDetail.some(function (m) {
      return m.email === email;
    }));
  });
  return memberEmail ? formatMessage(messages.ALREADY_A_MEMBER_ERROR, {
    email: memberEmail
  }) : undefined;
};